

.title h2{
    font-size: 20px;
    font-weight: 600;
    margin-top: 20px;
}
.container_table{
    width:100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

